import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./AboutMe.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faTerminal,
  faPaintBrush,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

class AboutMe extends Component {
  render() {
    return (
      <>
        <section className="about">
          <div className="headerWrapper">
            <h3 className="text-center">
              About Me<sup>1</sup>
            </h3>
          </div>
          <div className="row no-gutters">
            <div className="container">
              <div className="aboutMeCols">
                <p className="mb-5">
                  Hi! My name is David Kennedy and I am a Frontend Developer
                  based in Berlin, Germany. My main area of expertise is
                  building responsive progressive web applications. Having
                  initially worked as a graphic designer, I later transitioned
                  to Frontend web development and I am now looking for my next
                  opportunity.
                </p>
              </div>
              <div className="aboutMeCols">
                <div className="row mb-5">
                  <div className="col-12 col-md-4">
                    <div className="categories">
                      <FontAwesomeIcon icon={faCode} />
                      <h4>Development</h4>
                      <p>
                        I have over 8 years of experience working in web
                        development. The primary language that I work with is
                        Typescript. I use this experience to craft bespoke web
                        applications with frameworks like Angular and React.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="categories">
                      <FontAwesomeIcon icon={faTerminal} />
                      <h4>Dev Tools</h4>
                      <p>
                        Throughout my career I have worked with many development
                        processes and tools that greatly simplify and shorten
                        the development lifecycle. Tools such as Jenkins, Git
                        and Docker help with the continuous delivery of high
                        quality code.
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="categories">
                      <FontAwesomeIcon icon={faPaintBrush} />
                      <h4>UX Design</h4>
                      <p>
                        My previous experience as a graphic designer enables me
                        to work with designers to create seamless user
                        interfaces. I am proficient in tools such as Adobe's
                        Creative Suite, Figma and Sketch which makes converting
                        mockups to code effortless.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Link to="/about">
                  <button className="aboutBtn">
                    Learn More About Me <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* <Experience /> */}
      </>
    );
  }
}

export default AboutMe;
