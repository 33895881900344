import React from "react";
import classNames from "classnames";
import Menu from "../../components/Sidebar/Menu/Menu";
import { Link } from "react-router-dom";
import "./About.css";
import MenuButtonClose from "../../components/Sidebar/MenuButtons/MenuButtonClose";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../components/Footer/Footer";

class About extends React.Component {
  state = {
    menuOpen: false,
  };

  handleMenuClick() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  handleAnchorClick() {
    this.setState({
      menuOpen: false,
    });
  }

  render() {
    let toggleClasses = classNames({
      main: true,
      toggled: this.state.menuOpen,
    });

    return (
      <>
        <div className={toggleClasses}>
          <div className="topOuter">
            <MenuButtonClose
              open={this.state.menuOpen}
              onClick={() => this.handleMenuClick()}
            >
              <i className="fas fa-bars"></i>
            </MenuButtonClose>
            <Menu open={this.state.menuOpen}></Menu>
            <header className="about appHeader">
              <div className="container">
                <div className="headerWrapper">
                  <h1>
                    About <span>Me</span>
                  </h1>
                </div>
              </div>
            </header>
            <section className="aboutPage">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="aboutMeRow">
                      <p className="mb-4">
                        My name is David Kennedy and I am a Frontend Developer
                        based in Berlin, Germany. Having initially worked as a
                        graphic designer, I later transitioned to Frontend web
                        development where I now specialise in progressive web
                        applications.
                      </p>
                      <p className="mb-4">
                        My current role at Accenture involves working with
                        several high profile automotive brands. My latest
                        project involed developing an application that allowed
                        the user to fully customise their chosen vehicle.
                        Exterior paint colours, interior packages, battery types
                        and other features could be manipulated while a 3D
                        rendering of the car changed in sync with these
                        modifications. I wasresponsible for developing micro
                        frontend applications for product launch websites. These
                        websites of interconnected front-end applications must
                        reliably communicate with each other for the customer to
                        successfully complete the E-Commerce sales funnel. I
                        primarily worked with React (with Typescript) and
                        GraphQL for these projects.{" "}
                      </p>
                      <p className="mb-4">
                        I am currently looking a new opportunity in web
                        development where I can use my experience effectively
                        and hopefully immerse myself in a new challenge. I
                        believe that progressive web applications are the future
                        of web development and I would love to find a role that
                        uses Angular or React at the core of their tech stack.
                      </p>
                      <Link to="/experience">
                        <button>
                          My Experience
                          <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default About;
